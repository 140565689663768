// https://github.com/mmistakes/minimal-mistakes/issues/1219#issuecomment-326809412
// https://github.com/mmistakes/minimal-mistakes/issues/1219#issuecomment-583773445

@import "minimal-mistakes";

html {
  font-size: 14px;

  @include breakpoint($medium) {
    font-size: 16px;
  }

  @include breakpoint($large) {
    font-size: 18px;
  }

  @include breakpoint($x-large) {
    font-size: 20px;
  }
}
